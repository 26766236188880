import React from 'react';
import "./scss/app.scss";
import '@mantine/core/styles.css';
import {Footer} from "./components/Footer";
import {Routes, Route} from 'react-router-dom'
import {Home} from "./pages/Home";
import {MantineProvider} from '@mantine/core';
import {Instructions} from "./pages/Instructions";

import {FlagsProvider} from 'react-feature-flags';
import {flags} from './pages/Home';

function App() {
    return (
        <FlagsProvider value={flags}>
            <MantineProvider>
                <div className="wrapper">
                    {/*<Header />*/}
                    <div className='content'>
                        <Routes>
                            <Route path='/' element={<Home/>}/>
                            <Route path='/instructions' element={<Instructions/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </MantineProvider>
        </FlagsProvider>
    );
}

export default App;
