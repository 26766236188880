import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import styles from './InstructionCard.module.scss';

type InstructionCardProps = {
    title: string;
    size: string;
    link: string;
}

export const InstructionCard: React.FC<InstructionCardProps> = ({title, size, link}) => {
    return (
            <div className={styles.card}>
                <div className={styles.info}>
                    <RouterLink className={styles.instruction_title} target='_blank' to={link}>
                        {title}
                    </RouterLink>
                    <span>{size}</span>
                </div>
                <div className={styles.download_button}>
                    <RouterLink to='https://cloud.mail.ru/public/jagr/8WuTR5atQ'>
                        Скачать
                    </RouterLink>
                </div>
            </div>
    );
};

