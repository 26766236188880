import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import styles from './CookieAcceptionBlock.module.scss';
import Modal from "../Modal";
import cookiesData from "../../data/cookiesData.json";
import {useTranslation} from "react-i18next";

const CookieAcceptionBlock = () => {
    const { t, i18n } = useTranslation();
    const [showCookieConsent, setShowCookieConsent] = useState(!Cookies.get('cookieConsent'));
    const [cookieModalActive, setCookieModalActive] = React.useState(false);
    const cookies = cookiesData.data.split('\n');

    const handleConsent = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 }); // Сохраняем подтверждение в cookie
        setShowCookieConsent(false);
    };

    useEffect(() => {
        // Проверяем, было ли подтверждение использования файлов cookie
        if (Cookies.get('cookieConsent')) {
            setShowCookieConsent(false);
        }
        console.log(cookies)
    }, []);
    console.log(cookies)
    return (
        showCookieConsent ? (
            <div
                className={styles.wrapper}
            >
                <p>
                    Мы используем файлы cookie для улучшения вашего опыта. Продолжая использование
                    сайта, вы соглашаетесь с нашей <a href='#' onClick={() => setCookieModalActive(true)}>политикой использования файлов cookie.</a>
                </p>
                <div className={styles.acceptButton}>
                    <button onClick={handleConsent}>Принять</button>
                </div>

                <Modal active={cookieModalActive} setActive={setCookieModalActive}>
                    <div>
                        <h4>{t(`Информационные файлы cookie`)}</h4>
                        <svg
                            onClick={() => setCookieModalActive(false)}
                            className='clearIcon'
                            height="512px" id="Layer_1" version="1.1"
                            viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
                        </svg>
                    </div>

                    <div className={styles.cookies_wrapper}>
                        {cookies.map((cookies, index) => (
                            <p key={index}>{t(`${cookies}`)}</p>
                        ))}
                    </div>

                </Modal>

            </div>
        ) : null
    );
};

export default CookieAcceptionBlock;
