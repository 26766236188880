import React from 'react';
import styles from './Footer.module.scss';
import VCLogo from '../../assets/img/Logo4C.png'
import BKSLogo from '../../assets/img/banner_bks_white.png'
import {useTranslation} from "react-i18next";

export const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer className={styles.wrapper}>

            <div className={styles.rights}>
                <img src={VCLogo} alt='VC logo'/>
                <p>{t("© 2024 Вычислительный центр Белкоопсоюза")}</p>
                <span>{t("Все права защищены")}</span>
            </div>
            <div className={styles.working_time}>
                <h5>{t("Контакты")}</h5>
                <ul>
                    <li>{t("Режим работы с 8:30 – 17:30")}</li>
                    <li>{t("Тел: +375 17 373 65 62")}</li>
                    <li>{t("Почта: vcbks@bks.by")}</li>
                    <li>{t("Call - Центр: +375 29 624 00 24")}</li>
                    <li><span>{t("Адрес: Проспект Победителей, 17 г. Минск, 220004 Республика Беларусь")}</span></li>
                    <li><span><a href="../../assets/documents/polozhenie_o_politike_v_otnoshenii_obrabotki_personalnyh_dannyh.docx" download>
                            Политика обработки персональных данных
                        </a></span>

                    </li>
                </ul>
            </div>
            <div className={styles.icon_bks}>
                <img src={BKSLogo} alt='BKS logo'/>
            </div>
            <div className={styles.first_block}>
                <p>
                    {t("Зарегистрировано Минским городским исполнительным комитетом 16 января 2014 года в Едином государственном регистре юридеских лиц и индивидуальных предпринимателей. Свидетельство о государственной регистрации №100105017.")}
                </p>

            </div>


        </footer>
    );
};

