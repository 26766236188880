import React, { useEffect, useState } from 'react';
import gpsIcon from '../../assets/img/LogoCSS.webp';
import automatizationIcon from '../../assets/img/ST.webp';
import kassaIcon from '../../assets/img/LogoDS.webp';
import programIcon from '../../assets/img/LogoAP.webp';
import {useTranslation} from "react-i18next";


export const ScrollTrigger = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            // Вычисляем, насколько прокручена страница
            const scrollY = window.scrollY || document.documentElement.scrollTop;

            // Устанавливаем состояние isVisible в зависимости от прокрутки
            setIsVisible(scrollY > 500); // Измените значение, чтобы подстроить под вашу необходимость
        };

        // Добавляем слушатель события прокрутки
        window.addEventListener('scroll', handleScroll);

        // Очищаем слушатель события при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='flying-box'>
            {/* Блоки, которые вылетают */}
            <div>
                <div className={`flying-block ${isVisible ? 'visible' : ''} block1`}>
                    <img src={programIcon} alt='icon'/>
                    {t("Программное обеспечение")}
                </div>
                <div className={`flying-block ${isVisible ? 'visible' : ''} block2`}>
                    <img src={kassaIcon} alt='icon'/>
                    {t("Торговое оборудование")}
                </div>
            </div>
            <div>
                <div className={`flying-block ${isVisible ? 'visible' : ''} block3`}>
                    <img src={gpsIcon} alt='icon'/>
                    {t("GPS сопровождение")}
                </div>
                <div className={`flying-block ${isVisible ? 'visible' : ''} block4`}>
                    <img src={automatizationIcon} alt='icon'/>
                    {t("Автоматизация")}
                </div>
            </div>
        </div>
    );
};


