import React, {useEffect, useRef} from 'react';
import callCenter from '../assets/img/no_luck.svg';
import support from '../assets/img/support.webp';
import {Link as RouterLink} from 'react-router-dom';

import Clipboard from 'clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import instructions from '../data/insructionsData.json';
import memos from '../data/memosData.json';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WestIcon from '@mui/icons-material/West';
import { InstructionCard } from "../components/InstructionCard";

export const Instructions = () => {
    const phoneNumberRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clipboard = new Clipboard(phoneNumberRef.current as Element);

        clipboard.on('success', () => {
            toast.success('Номер скопирован!', { position: toast.POSITION.BOTTOM_RIGHT });
        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    const instructionList =  instructions.map((obj: any) =>
        <InstructionCard
        key={obj.link}
            {...obj}
        />
    );

    const memosList = memos.map((obj: any) =>
        <InstructionCard
            key={obj.link}
            {...obj}
        />
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='instructions-wrapper'>
            <div className='backToMain'>
                <RouterLink to='/'>
                    <WestIcon />
                    На главную
                </RouterLink>
            </div>

            <div className='page-title'>
                <div className='info'>
                    <h3>Найдите готовое решение</h3>
                    <span><strong>Популярные темы: </strong>
                        Руководства по эксплуатации КСА,
                        Памятки для кассиров
                    </span>
                </div>
                <div>
                    <img src={support} alt='technical-support' />
                </div>

            </div>
            <div className='instructions'>
                <h5>Руководства по эксплуатации</h5>
                <div className='instruction_block'>

                    {instructionList}

                </div>
                <h5>Памятки кассирам</h5>
                <div className='instruction_block'>
                    {memosList}
                </div>

            </div>
            <div className='documents'>

            </div>

            <div className='support'>
                <div className='info'>
                    <img src={callCenter} alt='call-center' />
                    <div>
                        <p>Не нашли решение? Мы поможем!</p>
                        <span>Позвоните нам, и мы поможем вам как можно скорее.</span>
                    </div>
                </div>

                <div
                     className='phone-number'
                     ref={phoneNumberRef}
                     data-clipboard-text="375296240024"
                     style={{ cursor: 'pointer' }}
                >
                    <LocalPhoneIcon fontSize='large'/>
                     +375296240024
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

