import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import NorthIcon from '@mui/icons-material/North';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        // Отображать кнопку, когда прокрутка больше 100 пикселей вниз
        setIsVisible(scrollY > 900);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Очистить слушателя событий при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Link
            to="top"
            smooth={true}
            duration={500}
            className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
        >
            <NorthIcon />
        </Link>
    );
};

export default ScrollToTopButton;
