import React from 'react';
import logo from "../assets/img/Logo4C.png";
import image from "../assets/img/444444444.webp"
import circle from '../assets/img/99999999.png'
import discussion from '../assets/img/963.png'
import hands from '../assets/img/new-hand.webp'
import meeting from '../assets/img/meeting.jpg'
import { ReactComponent as MySvg } from '../assets/img/1314955.svg'
import telegramIcon from '../assets/img/telegram.png'
import viberIcon from '../assets/img/viber.png'
import mailIcon from '../assets/img/mail.png'

import steps from '../data/StepsData'

import {ScrollTrigger} from "../components/ScrollTrigger";
import StepperCarousel from "../components/CarouselStepper";
import {Button, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import { Link as ScrollLink} from 'react-scroll';

import ScrollToTopButton from "../components/ScrollToTopButton";
import CookieAcceptionBlock from "../components/CookieAcceptionBlock";
import BurgerMenu from "../components/BurgerMenu";

import servicesData from '../data/servicesData.json';
import cookiesData from '../data/cookiesData.json';
import ServiceBlock from "../components/ServiceBlock";

import { Flags } from 'react-feature-flags';
import Modal from "../components/Modal";

//Добавил флаги для скрытия/отображения секций
export const flags = [
    {name: "activityBlock", isActive: false},
    {name: "textBlock", isActive: false},
    {name: "futureBlock", isActive: false}
]

export const Home = () => {
    const { t, i18n } = useTranslation();
    const [isHovered, setIsHovered] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [cookieModalActive, setCookieModalActive] = React.useState(false);
    const cookies = cookiesData.data.split('\n');
    // const inputRef = React.useRef<HTMLInputElement>(null);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const services = servicesData.map((obj: any) =>
        <ServiceBlock
        key={obj.number}
            {...obj}
        />
    )

    return (
        <div id='top' className='main'>

          <ScrollToTopButton />

            <CookieAcceptionBlock />

            <div className='title_block'>
                <div className='header'>
                    <div className='burgerMenu'>
                        <BurgerMenu />
                    </div>
                    <div className='home-nav'>
                        <nav>
                            <ScrollLink to="about" smooth={true} duration={500}>{t("О Нас")}</ScrollLink>
                            |
                            <ScrollLink to="industry" smooth={true} duration={500}>{t("Услуги")}</ScrollLink>
                            |
                            <ul>
                                <li
                                    className={`nav-item ${isHovered ? 'hovered' : ''}`}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    {t("Техническая поддержка")}
                                    <div className="additional-tabs">
                                        <RouterLink className="tab" to='https://bks1.by/gps/gps-zayavka' target='_blank' rel="noreferrer">{t("GPS обслуживание")}</RouterLink>
                                        <RouterLink className="tab" to='https://bks3.by' target='_blank' rel="noreferrer">{t("Обслуживание кассового оборудования")}</RouterLink>
                                        <RouterLink className="tab" to='https://bks1.by/bks/bks-request' target='_blank' rel="noreferrer">{t("Заявки Белкоопсоюз")}</RouterLink>
                                        <RouterLink className="tab" to='https://esbsrv.ddns.net/ftp/lsfusion' target='_blank' rel="noreferrer">{t("Инструкции")}</RouterLink>
                                    </div>
                                </li>
                            </ul>
                            |
                            <ScrollLink to="contacts" smooth={true} duration={500}>{t("Контакты")}</ScrollLink>
                        </nav>
                    </div>
                    <div className='search'>

                        {/*<div className='root'>*/}
                        {/*    <svg*/}
                        {/*        className='icon'*/}
                        {/*        height="512px" id="Layer_1" version="1.1"*/}
                        {/*        viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    >*/}
                        {/*        <path*/}
                        {/*            d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z"/>*/}
                        {/*    </svg>*/}
                        {/*    <input*/}
                        {/*        ref={inputRef}*/}
                        {/*        value={value}*/}
                        {/*        onChange={onChangeInput}*/}
                        {/*        className='input'*/}
                        {/*        placeholder="Поиск..."/>*/}
                        {/*    <svg*/}
                        {/*        onClick={onClickClear}*/}
                        {/*        className='clearIcon'*/}
                        {/*        height="512px" id="Layer_1" version="1.1"*/}
                        {/*        viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    >*/}
                        {/*        <path*/}
                        {/*            d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>*/}
                        {/*    </svg>*/}
                        {/*</div>*/}

                        <div className='language'>
                            <span
                                onClick={() => changeLanguage("ru")}>
                                RUS
                            </span>
                            |
                            <span
                                onClick={() => changeLanguage("en")}>ENG</span>
                            |
                            <span
                                onClick={() => changeLanguage("ch")}>CHI</span>
                        </div>

                    </div>
                </div>
                <div className='info'>
                    <div className='title'>
                        <img src={logo} alt='logo' />
                        <p>  {t("УНИТАРНОЕ ПРЕДПРИЯТИЕ ВЫЧИСЛИТЕЛЬНЫЙ ЦЕНТР БЕЛКООПСОЮЗА")}</p>

                    </div>

                    <div className='description'>
                        {t("Организация ориентирована на разработку внедрение и сопровождение программного обеспечения в различных ораганизациях, а также техническое обслуживание оргтехники и переферийных устройств.")}
                    </div>
                    <div className='more_button'>
                        <ScrollLink to="industry" smooth={true} duration={500}> {t("Узнать больше")}</ScrollLink>
                    </div>
                </div>
            </div>

            <div id='about' className='industry'>
                <div className='aboutUs'>
                    {t("Унитарное предприятие «Вычислительный центр Белкоопсоюза» основано в 1981 году. За прошедшие годы предприятием была обеспечена автоматизация и оптимизация безнес-процессов различных отраслей деятельности, оснащение наших партнеров современными системами автоматизации бухгалтерского учёта, учёта товародвижения и его управления на базе компьютерных программ, отвечающих требованиям законодательства Республики Беларусь. В настоящее время мы занимаемся разработкой программного обеспечения, поставкой оборудования, монтажом и обслуживанием GPS навигации автомобильного транспорта, обслуживанием компьютерной и копировальной техники, заправкой картриджей. Мы предлагаем нашим клиентам новые и современные решения.")}
                    <p></p>

                </div>
                <img className='aboutUs_img' src={image} alt='22' />
                <ScrollTrigger />
            </div>

            <div className='technologies'>
                <span id="industry"></span>
                <div className='title'>{t("Наша деятельность")}</div>
                <div className='lines'>
                        <MySvg className='lines'/>
                </div>
                <Flags authorizedFlags={['activityBlock']}>
                <div className='messages'>
                    <div className='first-block'>
                        <p className='title'>
                            {t("Техническое обслуживание")}
                        </p>
                        <p className='description'>
                            {t("Техническое обслуживание (ТО) вычислительной техники, POS-систем, КСА, оргтехники, администрирование серверов")}
                        </p>

                    </div>
                    <div className='second-block'>
                        <p className='title'>
                            {t("Разработка, внедрение и сопровождение ПО.")}
                        </p>
                        <p className='description'>
                            {t("Мы выполняем работы по внедрению и сопровождению программ, а также осуществляем разработку собственных решений")}
                        </p>
                    </div>
                    <div className='thirst-block'>
                        <p className='title'>
                            {t("Оптовая торговля и ремонт компьютерной техники")}
                        </p>
                        <p className='description'>
                            {t("Ремонт компьютеров и периферийного оборудования, оптовая торговля компьютерами, периферийным оборудованием, заправка картриджей")}
                        </p>
                    </div>
                    <div className='thirst-block'>
                        <p className='title'>
                            {t("Автоматизация торговых объектов")}
                        </p>
                        <p className='description'>
                            {t("Оборудование рабочих мест на объектах розничной, оптовой торговли, на предприятиях общественного питания с использованием программного обеспечения LS Trade Fushion")}
                        </p>
                    </div>
                </div>

                <img src={circle} alt='programmer'/>

                </Flags>

            </div>

            <div id='activity' className='discussion_block'>
                <div className='discussion_photo'>
                    <img src={discussion} alt='discussion'/>
                </div>
                <div className='principles'>

                    {services}

                </div>
            </div>

            <div id='instructions' className='triple-block'>
                <div className='background-top-block'></div>
                <div className='backLine'></div>
                <div className='middle'></div>
                <div className='frontLine'>
                    <img src={hands} alt='hands'></img>
                </div>
                <div className='text-block'>
                    <div>
                        <h2>
                            {t("Найти готовое решение")}
                        </h2>
                        <p>
                            {t("Популярные темы: Руководства по эксплуатации КСА, ФР, памятки для кассиров, удаленный доступ...")}
                        </p>
                        <RouterLink className='more-button' to="/instructions">{t("Узнать больше")}</RouterLink>
                    </div>

                </div>
            </div>
            <Flags authorizedFlags={['textBlock']}>
            <section id='technologies' className='new-technologies_block'>
                <h2 className='title'>{t("GPS / ГЛОНАСС мониторинг")}</h2>
                <div>
                    <p>{t("Наша компания является официальным дилером компании Wialon Operator в Республике Беларусь, обладает правами на распространение системы GPS/ГЛОНАСС мониторинга Wialon и оказание услуг спутникового мониторинга на её основе. Обслуживаем компании по всей территории Республики Беларусь.")}</p>
                    <p>{t("Мы предлагаем комплексный контроль транспорта, мобильных сотрудников и стационарных объектов с использованием технологий GPS/ГЛОНАСС: от установки оборудования и подключения к Wialon до создания индивидуальных решений для клиента.")}</p>
                    <p>{t("Данное решение позволяет осуществлять контроль местоположения транспортных средств в режиме реального времени, а также вести учет отработанного времени, километража и топлива, что в значительной степени сокращает расходы на обслуживание автопарка. На обслуживании находится более 2.5 тысяч объектов.")}</p>
                </div>
            </section>
            </Flags>
            <Flags authorizedFlags={['futureBlock']}>
            <section className='future-technology_block'>
                <div className='wrapper'>
                    <h2>{t("Наши будущие проекты")}</h2>

                    <div className='lines'><MySvg /></div>
                    <div className='triple-bottom_block'>
                        <div className='backLine'></div>
                        <div className='middle'>
                            <img src={meeting} alt='meeting'/>
                        </div>
                        <div className='frontLine'></div>
                        <div className='text-block'>
                            <StepperCarousel steps={steps} />
                        </div>
                    </div>
                </div>
            </section>
            </Flags>
            <section id='contacts' className='connection'>
                <div className='connection_wrapper'>
                    <div className='title'>{t("Напишите нам")}</div>
                    <div className='input'>
                        <TextField
                            InputProps={{
                                style: {
                                    borderRadius: "0px",
                                }
                            }}
                            id="outlined-basic"
                            label={t("Ваше сообщение")}
                            variant="outlined"
                            multiline
                            maxRows={4}
                            fullWidth
                            value={message}
                            onChange={(event: any) => {
                                setMessage(event.target.value);
                            }}
                        />
                        <Button
                            style={{ borderRadius: 0, backgroundColor: '#232b79', boxShadow: 'none'  }}
                            variant="contained"
                            onClick={() => setMessage('')}
                        >
                            {t("Отправить")}
                        </Button>
                    </div>
                    <div className='connection_bottom'>
                        <p>{t("Наши Мессенджеры")}</p>
                        <div className='icons'>
                            <img src={telegramIcon} alt='telegram icon'/>
                            <img src={viberIcon} alt='viber icon'/>
                            <a href='mailto:vcbks@bks.by'><img src={mailIcon} alt='mail icon'/></a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

