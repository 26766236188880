import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEnglish from "./locales/en/translation.json";
import translationRussian from "./locales/ru/translation.json";
import translationChinese from './locales/ch/translation.json';
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


export const defaultNS = 'en'
export const resources = {
    en: {
        translation: translationEnglish,
    },
    ru: {
        translation: translationRussian,
    },
    ch: {
        translation: translationChinese,
    },
} as const;

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: 'ru',
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        caches: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
})



// i18n.use(initReactI18next).init({
//     lng: "en",
//     ns: ["ns1", "ns2"],
//     defaultNS,
//     resources,
// });