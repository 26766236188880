import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StepperCarousel = ({ steps, interval = 3000 }: any) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleStepChange = (index: any) => {
        setActiveStep(index);
    };

    return (
        <div>
            <Carousel
                selectedItem={activeStep}
                onChange={handleStepChange}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                emulateTouch
                infiniteLoop
                autoPlay
                interval={interval}
            >
                {steps.map((step: any, index: any) => (
                    <div key={index}>{step.content}</div>
                ))}
            </Carousel>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {steps.map((step: any, index: any) => (
                    <span
                        key={index}
                        onClick={() => handleStepChange(index)}
                        style={{
                            display: 'inline-block',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            backgroundColor: index === activeStep ? '#007bff' : '#ccc',
                            width: '10px',
                            height: '10px',
                            margin: '0 5px',
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default StepperCarousel;
