// BurgerMenu.tsx
import React, { useState } from 'react';
import styles from './BurgerMenu.module.scss';

import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';

import { slide as Menu } from 'react-burger-menu'
import {useTranslation} from "react-i18next";
import { Link } from 'react-scroll';



const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const closeMenu = () => setIsOpen(false);
    const handleStateChange = (state: any) => setIsOpen(state.isOpen);

    return (

    <Menu
        burgerButtonClassName={ styles.bmBurgerButton }
        burgerBarClassName={ styles.bmBurgerBars }
        crossButtonClassName={ styles.bmCrossButton }
        crossClassName={ styles.bmCross }
        menuClassName={ styles.bmMenu }
        morphShapeClassName={ styles.bmMorphShape }
        itemListClassName={ styles.bmItemList }
        overlayClassName={ styles.bmOverlay }
        // styles={styles}
        isOpen={isOpen}
        onStateChange={handleStateChange}
        customBurgerIcon={ <MenuIcon fontSize='large' sx={{ color: grey[50] }}/> }
    >
        <Link to="about" smooth={true} duration={500} onClick={closeMenu}>{t("О Нас")}</Link>
        <Link to="activity" smooth={true} duration={500} onClick={closeMenu}>{t("Услуги")}</Link>
        <Link to="technologies" smooth={true} duration={500} onClick={closeMenu}>{t("Технологии")}</Link>
        <a href='https://bks1.by/gps/gps-zayavka' target='_blank' rel="noreferrer">{t("GPS обслуживание")}</a>
        <a href='https://bks3.by/' target='_blank' rel="noreferrer">{t("Обслуживание кассового оборудования")}</a>
        <a href='https://bks1.by/bks/bks-request' target='_blank' rel="noreferrer">{t("Заявки Белкоопсоюз")}</a>
        <a href='https://esbsrv.ddns.net/ftp/lsfusion' target='_blank' rel="noreferrer">{t("Инструкции")}</a>

            </Menu>

    );
};

export default BurgerMenu;
